import http from "../environment";

const getByStd = (id) => {
  console.log("asdfghjkl");
  return http.get(`/video/courses/get?std_id=${id}`);
};

const VideoCoursesService = {
  getByStd,
};

export default VideoCoursesService;
