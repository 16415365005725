import React, { useEffect, useState } from "react";
import LanguageService from "../api config/language";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../Css/courses.css";
import slideimage from "../assets/images/cover.png";
import MasterService from "../api config/master";
// import Iframe from "react-iframe";
import VideoCoursesService from "../api config/videoCourses";

const Courses = () => {
  const [videoList, setVideoList] = useState();
  const [stdList, setStdList] = useState();
  // const [board, setBoard] = useState(false);

  useEffect((id) => {
    MasterService.stdGetAll().then(
      (res) => {
        console.log(res.data.data);
        setStdList(res.data.data);
      },
      [setStdList]
    );
    VideoCoursesService.getByStd(3).then(
      (res) => {
        console.log(res.data.data);
        // setBoard(false);
        setVideoList(res.data.data);
      },
      [setVideoList]
    );
  }, []);

  return (
    <>
      <div className="container">
        {stdList?.map((stdItem, index) => {
          // Filter the video list based on the std ID
          const stdVideoList = videoList.filter(
            (videoItem) => videoItem.std_id === stdItem.id
          );
          return (
            <div className="std" key={stdItem.id}>
              <h1>std {stdItem.name}</h1>
              <div className="row">
                <div className="slides">
                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={30}
                    slidesPerView={4}
                    navigation
                    //   pagination={{ clickable: true }}
                    //   scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                    grid={{
                      fill: "row",
                      rows: 3,
                    }}
                    breakpoints={{
                      300: {
                        slidesPerView: 1,
                        grid: {
                          fill: "row",
                          rows: 1,
                        },
                      },
                      400: {
                        slidesPerView: 2,
                        grid: {
                          fill: "row",
                          rows: 1,
                        },
                      },
                      768: {
                        slidesPerView: 3,
                        grid: {
                          fill: "row",
                          rows: 1,
                        },
                      },
                      1000: {
                        slidesPerView: 4,
                        grid: {
                          fill: "row",
                          rows: 1,
                        },
                      },
                    }}
                  >
                    <div className="col">
                      {stdVideoList.map((videoItem) => (
                        <SwiperSlide key={videoItem.id}>
                          <div>{videoItem.name}</div>
                          <div className="card card-video">
                            <div className="card-body">
                              {/* <img src={videoItem.url} alt="video" /> */}
                              <div class="embed-responsive embed-responsive-16by9">
                                <iframe
                                  class="embed-responsive-item"
                                  src={videoItem.url}
                                  // allowfullscreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Courses;
