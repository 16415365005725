import { useFormik } from "formik";
import React, { useEffect } from "react";
// import "../Contact Us/contactUs.css";
// import "../../css/module.scss";
// import { GoogleMap, LoadScript } from "@react-google-maps/api";
import InquiryService from "../api config/inquiry";
import "../Css/ContactUs.css";
// import instagram from "../../assets/image/instagram.png";
import { BsInstagram } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
// import Footer from "../../components/Footer";

const ContactUs = () => {
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_id: "",
      primary_contact_number: "",
      message: "",
      status: "false",
    },
    onSubmit: (values) => {
      console.log("values", values);
      InquiryService.create(values).then((res) => {
        console.log(res);
      });
    },
  });

  // const containerStyle = {
  //   width: "100%",
  //   height: "500px",
  // };

  // const center = {
  //   lat: 23.0259745001,
  //   lng: 72.5572554334,
  // };

  const { handleChange, handleSubmit } = formik;

  return (
    <section className="contact-us">
      <div className="container contactUs-sec">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 contact-details">
            <h2>Get In Touch</h2>
            <p>Let's Make Some Magic Together</p>
            <div className="row">
              <form class="row g-5" onSubmit={handleSubmit}>
                <div class="col-12 col-sm-12 col-md-6">
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="First Name"
                          type="text"
                          name="first_name"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="Last Name"
                          type="text"
                          name="last_name"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 field">
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="Email Id"
                          type="text"
                          name="email_id"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 field">
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="Contact Number"
                          type="number"
                          name="primary_contact_number"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 field">
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <textarea
                          className="input100 has-val"
                          placeholder="Message"
                          type="text"
                          name="message"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="submit btn">
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className="col contact-details">
            <div className="container details">
              <div className="card c">
                <div className="card-body card-info">
                  <div className="container box">
                    <div className="col-12 col-sm-6 col-md-6 name">
                      <h5 className="info ">Contact Info</h5>
                      <h5 className="data ">Malhar Mehta</h5>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-3 name">
                        <h6 className="h6">Mobile Number</h6>
                      </div>
                      <div className="col-9 col-sm-12 col-md-9">
                        <h6 className="data name"> +91 9723816597</h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col--12 col-sm-12 col-md-12 col-lg-3 name">
                        <h6 className="h6">Email</h6>
                      </div>
                      <div className="col-9">
                        <h6 className="data name"> malhar@bintech.services</h6>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col--12 col-sm-12 col-md-12 col-lg-3 name">
                        <h6 className="h6">Address</h6>
                      </div>
                      <div className="col-9">
                        <h6 className="data name">
                          401, Samedh Complex <br />
                          Panchwati Cross Road <br />
                          Ahmedabad,380009 <br />
                          Gujarat
                        </h6>
                      </div>
                    </div>

                    <div className="row icon">
                      <a
                        href="https://www.instagram.com/gujaratisahitya.in"
                        target="_blank"
                      >
                        <BsInstagram />
                      </a>
                      <a
                        href="https://www.youtube.com/@gujarati.sahitya"
                        target="_blank"
                      >
                        <BsYoutube />
                      </a>
                      <a
                        href="https://twitter.com/your_username_here"
                        target="_blank"
                      >
                        <AiFillTwitterCircle />
                      </a>
                      <a
                        href="https://www.facebook.com/your_username_here"
                        target="_blank"
                      >
                        <BsFacebook />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
