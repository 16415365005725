// import ExpenseItem from "./components/ExpenseItem";
import Header from "./Components/header";
function App() {
  return (
    // <div>
    <Header />
    // </div>;
  );
}

export default App;
