import React, { useEffect, useState } from "react";
import "../Css/AnswerKey.css";
import LanguageService from "../api config/language";
import Board from "./board";

const AnswerKey = () => {
  const [languageList, setLanguageList] = useState();

  useEffect(() => {
    LanguageService.getAll().then(
      (res) => {
        console.log(res.data);
        setLanguageList(res.data.data);
      },
      [setLanguageList]
    );
  }, []);

  return (
    <div className="container ans-key">
      <div className="ans">
        <div className="row">
          {/* <h4 className="h4">Which medium do you study in?</h4> */}
          {/* <a href="chapter"> */}
          {/* <Board /> */}
          <h4>answerKey</h4>
          {/* </a> */}
        </div>
        {/* <div className="row">
          {languageList?.map((item) => {
            return (
              <div key={item.id} className="col-12 col-sm-6 col-md-6">
                <a href="chapter">
                  <button type="button" className="button">
                    <h1 className="T Th">{item.description}</h1>
                    <p className="T">{item.name}</p>
                  </button>
                </a>
              </div>
            );
          })}
        </div> */}
      </div>
    </div>
  );
};

export default AnswerKey;
