// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Link,
//   NavLink,
// } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import "../Css/Component.css";
// import logo from "../assets/images/logo.png";

// import AnswerKey from "../Modules/answerKey";
// import ContactUs from "../Modules/contactUs";
// import Home from "../Modules/home";
// import Chapter from "../Modules/chapter";
// import Login from "./login";
// import Std from "../Modules/std";
// import Content from "../Modules/content";
// import Logout from "../assets/images/logout.png";
// import TextBook from "../Modules/textbook";
// import Courses from "../Modules/courses";
// import PracticeTest from "../Modules/practiceTest";

// const Header = () => {
//   const [userInfo, setUserInfo] = useState();
//   const [overlay, setOverlay] = useState(false);
//   const [authToken, setAuthToken] = useState();
//   const auth_token = JSON.parse(sessionStorage.getItem("auth_key"));
//   const user_info = JSON.parse(sessionStorage.getItem("user_info"));

//   useEffect(() => {
//     if (auth_token) {
//       setUserInfo(user_info);
//       setAuthToken(auth_token);
//     }
//     window.addEventListener("scroll", isSticky);
//     return () => {
//       window.removeEventListener("scroll", isSticky);
//     };
//   }, [authToken]);

//   const isSticky = (e) => {
//     const header = document.querySelector(".header");
//     const scrollTop = window.scrollY;
//     scrollTop >= 50
//       ? header.classList.add("is-sticky")
//       : header.classList.remove("is-sticky");
//   };

//   const logout = () => {
//     console.log("hello");
//     sessionStorage.clear();
//     console.log(user_info);
//     setTimeout(function () {
//       window.location.reload(true);
//     }, 50);
//   };
//   return (
//     <Router>
//       <header id="header" className="header black_bkg">
//         <div className="container-fluid">
//           <nav className="navbar bg-light">
//             <div className="logo">
//               <a className="navbar-brand" href="home">
//                 <img
//                   src={logo}
//                   alt="Logo"
//                   width="30"
//                   height="24"
//                   className="d-inline-block align-text-top image"
//                 />
//               </a>
//             </div>

//             <div className="link">
//               <ul className="service">
//                 <li className="servicemenu">
//                   {/* <NavLink
//                     to="login"
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                     style={{
//                       textDecoration: "none",
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   >
//                     Login/Register
//                   </NavLink> */}

//                   {!authToken ? (
//                     <NavLink
//                       to="login"
//                       className="servicemenu"
//                       style={{
//                         textDecoration: "none",
//                         color: "black",
//                         fontSize: "20px",
//                       }}
//                     >
//                       Login
//                     </NavLink>
//                   ) : null}
//                   {authToken ? (
//                     <NavLink
//                       to="home"
//                       // className="servicemenu"appi
//                       style={{
//                         textDecoration: "none",
//                         color: "black",
//                         fontSize: "20px",
//                       }}
//                     >
//                       {/* Logout */}

//                       <button onClick={() => logout()}>Logout</button>
//                     </NavLink>
//                   ) : null}
//                 </li>

//                 <li className="servicemenu">
//                   <NavLink
//                     to="text-book"
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                     style={{
//                       textDecoration: "none",
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   >
//                     TextBook
//                   </NavLink>
//                 </li>

//                 <li className="servicemenu">
//                   <NavLink
//                     to="courses"
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                     style={{
//                       textDecoration: "none",
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   >
//                     Courses
//                   </NavLink>
//                 </li>

//                 <li className="servicemenu">
//                   <NavLink
//                     to="practice-test"
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                     style={{
//                       textDecoration: "none",
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   >
//                     PracticeTest
//                   </NavLink>
//                 </li>

//                 <li className="servicemenu">
//                   <NavLink
//                     to="answer-key"
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                     style={{
//                       textDecoration: "none",
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   >
//                     AnswerKey
//                   </NavLink>
//                 </li>

//                 <li className="servicemenu">
//                   <NavLink
//                     to="contact-us"
//                     className={({ isActive }) => (isActive ? "active" : "")}
//                     style={{
//                       textDecoration: "none",
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   >
//                     ContactUs
//                   </NavLink>
//                 </li>
//               </ul>
//             </div>
//           </nav>
//         </div>
//       </header>
//       <Routes>
//         <Route exact path="/" element={<Home />} />
//         <Route path="home" element={<Home />} />
//         <Route path="answer-key" element={<AnswerKey />} />
//         <Route path="text-book" element={<TextBook />} />
//         <Route path="courses" element={<Courses />} />
//         <Route path="practice-test" element={<PracticeTest />} />
//         <Route path="chapter" element={<Chapter />} />
//         <Route path="content" element={<Content />} />
//         <Route path="std" element={<Std />} />
//         <Route path="login" element={<Login />} />
//         <Route path="contact-us" element={<ContactUs />} />
//       </Routes>
//     </Router>
//   );
// };

// export default Header;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  NavLink,
} from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";

// import Home from "../DefaultLayout/DefaultLayout";
// import AboutUs from "../Modules/About us/AboutUs";
// import TheShop from "../Modules/The Shop/TheShop";
// import ContactUs from "../Modules/Contact Us/ContactUs";
// import Login from "../Modules/Login/Login";
// import ProductInfo from "./ProductInfo";
// import Cart from "./Cart";
// import Product from "../Modules/Product/product";
// import Search from "./Search";
// import Lookbook from "../Modules/Lookbook/Lookbook";
// import Checkout from "../Modules/Checkout/Checkout";

import "../Css/Component.css";
// import "../css/header.css";

// import Logout from "../assets/images/power-off.png";
import logo from "../assets/images/logo.png";
// import bag from "../assets/images/cart.png";
// import bar from "../assets/images/download (1).png";
// import PaymentSuccess from "./PaymentSuccess";
// import PaymentFailed from "../Modules/PaymentFailed/PaymentFailed";

import { BsPerson } from "react-icons/bs";
import { BsHandbag } from "react-icons/bs";

import { FaRegUser } from "react-icons/fa";
import { MdOutlineShoppingBag } from "react-icons/md";

import { BsXLg } from "react-icons/bs";
// import AnswerKey from "../Modules/answerKey";
import Home from "../Modules/home";
import TextBook from "../Modules/textbook";
import Courses from "../Modules/courses";
import PracticeTest from "../Modules/practiceTest";
import AnswerKey from "../Modules/answerKey";
import ContactUs from "../Modules/contactUs";
import Chapter from "../Modules/chapter";
import Content from "../Modules/content";
import Std from "../Modules/std";
import Login from "./login";
import Board from "../Modules/board";
// import StdCourses from "../Modules/stdCourses";
// import BoardCourses from "../Modules/boardCourses";

const Header = () => {
  const [userInfo, setUserInfo] = useState();
  const [overlay, setOverlay] = useState(false);
  const [authToken, setAuthToken] = useState();
  const auth_token = JSON.parse(sessionStorage.getItem("auth_key"));
  const user_info = JSON.parse(sessionStorage.getItem("user_info"));

  useEffect(() => {
    if (auth_token) {
      setUserInfo(user_info);
      setAuthToken(auth_token);
    }
    // window.addEventListener("scroll", isSticky);
    // return () => {
    //   window.removeEventListener("scroll", isSticky);
    // };
  }, [authToken]);

  const isSticky = (e) => {
    // const header = document.querySelector(".header");
    // const scrollTop = window.scrollY;
    // scrollTop >= 50
    //   ? header.classList.add("is-sticky")
    //   : header.classList.remove("is-sticky");
  };

  // const logout = () => {
  //   sessionStorage.clear();
  //   setTimeout(function () {
  //     window.location.reload(true);
  //   }, 6000);
  // };

  return (
    <>
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>E-library</title>
          <link rel="icon" type="image/png" href={logo} />
        </Helmet>
      </div>
      <div className="wrapper">
        <Router>
          <nav
            className="py-3 navbar navbar-expand-lg fixed-top"
            id="navbarMain"
          >
            <div class="container-fluid nav-bar nav-underline">
              <NavLink to="home">
                <a class="navbar-brand ms-3 ms-sm-5">
                  <img
                    src={logo}
                    alt="brand-logo"
                    height={"40px"}
                    className="brand-logo"
                  />
                </a>
              </NavLink>
              <button
                class="navbar-toggler me-sm-5"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div
                class="offcanvas offcanvas-start"
                data-bs-backdrop="false"
                tabIndex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div class="offcanvas-header pb-0 px-4 pt-5">
                  <img
                    src={logo}
                    alt=""
                    className="img-fluid"
                    id="offcanvasNavbarLabel"
                  />
                  <div className="container d-flex justify-content-end align-items-center">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      {/* <BsXLg /> */}
                    </button>
                  </div>
                </div>
                <hr className="d-block d-lg-none" />

                <div class="offcanvas-body px-4 py-0">
                  <ul class="navbar-nav justify-content-end flex-grow-1">
                    {/* <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="login" className="nav-link fs-6">
                          Login
                        </NavLink>
                      </span>
                    </li> */}

                    <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="board" className="nav-link fs-6">
                          Text Book
                        </NavLink>
                      </span>
                    </li>

                    <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="courses" className="nav-link fs-6">
                          Courses
                        </NavLink>
                      </span>
                    </li>

                    {/* <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="practice-test" className="nav-link fs-6">
                          Practice Test
                        </NavLink>
                      </span>
                    </li> */}

                    <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="answer-key" className="nav-link fs-6">
                          Answer Key
                        </NavLink>
                      </span>
                    </li>

                    <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        <NavLink to="contact-us" className="nav-link fs-6">
                          Contact Us
                        </NavLink>
                      </span>
                    </li>
                    {/* </ul> */}

                    {/* <ul class="navbar-nav justify-content-end flex-grow-1 pe-3"> */}
                    <li class="nav-item mx-2">
                      <span data-bs-dismiss="offcanvas" aria-label="Close">
                        {authToken ? (
                          <NavLink to="home" className="nav-link">
                            Logout
                            {/* <FaRegUser /> */}
                          </NavLink>
                        ) : (
                          <NavLink to="login" className="nav-link">
                            Login
                            {/* <FaRegUser /> */}
                          </NavLink>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>

          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="answer-key" element={<AnswerKey />} />
            <Route path="text-book" element={<TextBook />} />
            <Route path="courses" element={<Courses />} />
            {/* <Route path="practice-test" element={<PracticeTest />} /> */}
            <Route path="chapter" element={<Chapter />} />
            <Route path="content" element={<Content />} />
            <Route path="board" element={<Board />} />
            {/* <Route path="boardCourses" element={<BoardCourses />} /> */}
            <Route path="std" element={<Std />} />
            {/* <Route path="stdCourses" element={<StdCourses />} /> */}
            <Route path="textBook" element={<TextBook />} />
            <Route path="login" element={<Login />} />
            <Route path="contact-us" element={<ContactUs />} />
          </Routes>
        </Router>
      </div>

      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </>
  );
};

export default Header;
