import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LanguageService from "../api config/language";
// import ReactSwitch from "react-switch";
// import ToggleSwitch from "../Components/toggle";
// import TripleToggle from "../Components/threeWayToggle";
import "../Css/board.css";

const Board = () => {
  const navigate = useNavigate();

  const handleBoardClick = (boardId) => {
    console.log(`Board clicked with id ${boardId}`);

    navigate(`/std`, { state: { id: boardId } });
  };
  if (window.location.href.includes("text-book")) {
    // Attach the event listener to the element you want to trigger the function
    const boardElement = document.getElementById("board");
    boardElement.addEventListener("click", () =>
      handleBoardClick(boardElement.id)
    );
  }
  return (
    <div className="container board-sec">
      <div className="row board">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 card-square">
          <a href="" className="std" onClick={() => handleBoardClick(1)}>
            <div className="card card-bg">
              <div className="card-body">
                <h3 className="board-name">CBSE</h3>
              </div>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 card-square">
          <a href="" className="std" onClick={() => handleBoardClick(2)}>
            <div className="card card-bg">
              <div className="card-body">
                <h3 className="board-name">GSEB</h3>
              </div>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 card-square">
          <a href="" className="std" onClick={() => handleBoardClick(3)}>
            <div className="card card-bg">
              <div className="card-body">
                <h3 className="board-name">IB</h3>
              </div>
            </div>
          </a>
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-6 card-square">
          <a href="" className="std" onClick={() => handleBoardClick(4)}>
            <div className="card card-bg">
              <div className="card-body">
                <h3 className="board-name">ICSE</h3>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Board;
