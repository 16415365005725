import http from "../environment";

const getAll = () => {
  return http.get(`/inquiry/get`);
};

const create = (data) => {
  console.log(data);
  return http.post(`/inquiry/save`, data);
};

const InquiryService = {
  getAll,
  create,
};

export default InquiryService;
