import React, { useEffect, useState } from "react";
import cover from "../assets/images/cover.png";
import "../Css/home.css";

const Home = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 text">
          <h3 className="h3">
            Computer Studies
            <br />
            Solution / Answer Key
          </h3>
          <p className="p">
            Gujarat Board Std 10 and 12 <br />
            Computer Textbook Exercise Solution <br />
            Answer Key for Science and <br />
            Commerce
          </p>
          <a href="answer-key">
            <button type="button" class="btn">
              View More
            </button>
          </a>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <img src={cover} alt="image" className="img-responsive " />
        </div>
      </div>
    </div>
  );
};

export default Home;
