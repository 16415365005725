import http from "../environment";

const stdGetAll = () => {
  return http.get(`/std/get`);
};

const stdById = (id) => {
  return http.get(`/std/get?board_id=${id}`);
};

const MasterService = {
  stdGetAll,
  stdById,
};

export default MasterService;
