import http from "../environment";

const getAll = () => {
  return http.get(`/textbook/get`);
};
const getByStdAndBoard = (std_id, boardId) => {
  return http.get(`/textbook/get?board=${boardId}&std_id=${std_id}`);
};

const TextBookService = {
  getAll,
  getByStdAndBoard,
};

export default TextBookService;
