// import React, { useEffect, useState } from "react";
// import "../Css/chapter.css";
// import MasterService from "../api config/master";
// import ChapterService from "../api config/chapter";
// import Login from "../Components/login";

// const Chapter = () => {
//   const [chapterList, setChapterList] = useState();
//   const [stdList, setStdList] = useState();
//   const [content, setContent] = useState();
//   const [chapterData, setChapterData] = useState();
//   const is_login = JSON.parse(sessionStorage.getItem('is_login'));
//     const auth_token = JSON.parse(sessionStorage.getItem('auth_key'));
//     const user_info = JSON.parse(sessionStorage.getItem('user_info'));
//     // const login_data = JSON.parse(sessionStorage.getItem('login_data'));

//   useEffect(() => {
//     MasterService.stdGetAll().then(
//       (res) => {
//         console.log(res.data);
//         setStdList(res.data.data);
//         // getByLanguageAndStd(res.data.data)
//       },
//       [setStdList,getByLanguageAndStd]
//     );

//     ChapterService.getAll().then(
//       (res) => {
//         console.log(res.data);
//         setChapterList(res.data.data);
//         getByLanguageAndStd(res.data.data[0].id)
//       },
//       [setChapterList]
//     );

//     // ChapterService.getByLanguageAndStd().then(
//     //   (res) => {
//     //     console.log(res.data);
//     //     setChapterList(res.data.data);
//     //   },
//     //   [setChapterList]
//     // );
//   }, []);

//   // const getByLanguageAndStd = (id) => {
//   //   // if (categoryFilter.indexOf(id) === -1) {
//   //   //   setCategoryFilter((prevArray) => [...prevArray, id]);
//   //   // }
//   //   ChapterService.getByLanguageAndStd(id).then((res) => {
//   //     setChapter(res.data.data);
//   //   });
//   // };

//   const getByLanguageAndStd = (id) => {
//     console.log(id);
//     ChapterService.getByLanguageAndStd(id).then(
//       (res) => {
//         console.log(res.data.data);
//         setChapterData(res.data.data);
//       },
//       [setChapterData]
//     );

//     getData();
//   };

//   const getData = () => {
//     if (auth_token) {
//       if (user_info.is_trial && (is_login || !is_login)) {
//           ChapterService.getByLanguageAndStd()
//               .then(res => {
//                 setContent(res.data.data);
//                   getByLanguageAndStd(res.data.data[0].id);
//               })
//               .catch(e => console.log(e));
//       }
//       else{
//         ChapterService.getAll().then(
//           (res) => {
//             console.log(res.data);
//             setChapterList(res.data.data);
//             getByLanguageAndStd(res.data.data[0].id)
//           },
//           [setChapterList]
//         )
//       }
//   }
// }

//   return (
//     <div className="container">
//       <div className="row chapter">
//         {stdList?.map((item) => {
//           return (
//             <div className="col">
//               <a href="#" className="std "
//                             aria-current="page"
//                                   onClick={() => getByLanguageAndStd(item.id)}
//                                   >
//                 <h3 className="hover-underline-animation">Std {item.name}</h3>
//               </a>
//             </div>
//           );
//         })}
//         {/* <div className="col">
//           <a href="#" className="std">
//             <h4 className="hover-underline-animation">Std 12</h4>
//           </a>
//         </div> */}
//       </div>
//       {chapterList?.map((item) => {
//         return (
//           <div className="row" key={item.id}>
//             <div className="card">
//               <div className="card-body">
//                 <div>
//                   <a href="content" className="content" onClick={Login}>
//                    <h5 className="ch"> {item.chapter_number}. {item.name}</h5>
//                   </a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default Chapter;

import React, { useEffect, useState } from "react";
// import Footer from "../../components/Footer";
// import "../Lookbook/Lookbook.scss";
// import "../../js/main";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/css/scrollbar";
// import slider1 from "../../../src/assets/images/slider.png";
import MasterService from "../api config/master";
import ChapterService from "../api config/chapter";

const Chapter = (props) => {
  const [stdData, setStdData] = useState();
  const [languageList, setLanguageList] = useState();
  const [chapterData, setChapterData] = useState();
  useEffect(() => {
    MasterService.stdGetAll().then(
      (res) => {
        console.log(res.data.data[0].id);
        setStdData(res.data.data);
        // setLanguageList(res.data.data);
        getChapterData(res.data.data[0].id);
      },
      [setStdData]
    );
  }, []);

  const getChapterData = (language_id, std_id) => {
    ChapterService.getByLanguageAndStd(language_id, std_id).then(
      (res) => {
        console.log(res.data.data);
        setChapterData(res.data.data);
        setLanguageList(res.data.data);
        // setStdData(res.data.data);
      },
      [setChapterData]
    );
  };

  // const handleClick = (item) => {
  //   setChapterData(item);
  // };

  return (
    <>
      <section className="chapter">
        <div id="main" className="my-5">
          <div className="container">
            <div className="portfolio-filter">
              <ul className="nav">
                <Swiper
                  spaceBetween={300}
                  slidesPerView={3}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  {stdData?.map((item, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <li className="nav-item" data-filter="*">
                          std
                          <a
                            className="nav-link"
                            aria-current="page"
                            // data-fancybox="product-card"
                            style={{ color: "black" }}
                            href="#"
                            onClick={() => getChapterData(item.id)}
                          >
                            {item.name}
                          </a>
                        </li>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="container portfolio mb-5"
          data-aos="zoom-in-up"
          data-aos-duration="1000"
        >
          <div
            className="row g-lg-4 gy-3"
            data-masonry='{"percentPosition": true}'
          >
            {chapterData?.map((item) => {
              return (
                // <div
                //   key={item.id}
                //   className="col-lg-4 col-md-6 col-sm-1"
                //   data-aos="fade-up"
                //   data-aos-anchor-placement="center-bottom"
                // >
                //   <a href="#">
                //     <div className="card text-white seasons">
                //       <div className="hover-overlay"></div>
                //       <img
                //         src={item.thumbnail_image}
                //         className="card-img"
                //         alt="..."
                //       />
                //       <div className="card-img-overlay">
                //         <aside className="portfolio-categories">Seasons</aside>
                //         <h3>Square Design</h3>
                //       </div>
                //     </div>
                //   </a>
                // </div>
                <div className="row" key={item.id}>
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <a href="content" className="content">
                          <h5 className="ch">
                            {" "}
                            {item.chapter_number}. {item.name}
                          </h5>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Chapter;
