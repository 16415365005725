import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import "../Css/login.css";
// import "../css/util.css";
// import "../js/main.js";
// import logo from "../assets/img/logo.PNG";
// import facebookImg from "../assets/img/facebook.png";
// import googleImg from "../assets/img/google.png";
import UserService from "../api config/login";
// import ForgotPasswordService from "../api-config/forgot-password";

const Login = (props) => {
  const navigate = useNavigate();
  const { isModal, setIsOpen, setForgotPassword } = props;
  const emailPattern =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)$/;

  const [showPasswordField, setPasswordField] = useState(false);
  const [showSignUpForm, setSignUpForm] = useState(false);
  const [beforeValidate, setValidate] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [btnTitle, setbtnTitle] = useState("lets'go");
  const [forgotPass, setForgotPass] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [otp, setOTP] = useState("");
  const [transform, setTransform] = useState("50%");

  const formik = useFormik({
    initialValues: {
      email_id: "",
      password: "",
      is_web_signin: true,
      first_name: "",
      last_name: "",
      primary_contact_number: "",
      dob: "",
      user_role_id: 1,
    },
    validateOnChange: false,
    onSubmit: (values) => {
      if (!beforeValidate) {
        handleBlur(values.email_id);
      } else {
        isLogin ? loginAPI(values) : signupAPI(values);
      }
      console.log("submit");
    },
  });

  const loginAPI = (values) => {
    if (testPattern(values.email_id)) {
      const data = {
        email_id: values.email_id,
        password: values.password,
      };
      UserService.userLogin(data)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            setData(res.data);
          } else {
            // toast.error(res.data.message);
          }
        })
        .catch((e) => console.log(e));
    } else {
      const data = {
        primary_contact_number: values.email_id,
        password: values.password,
      };
      UserService.userLogin(data)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            setData(res.data);
          } else {
            // toast.error(res.data.message);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  // function to store data in session storage after login with email_id or phone number
  const setData = (data) => {
    sessionStorage.setItem("auth_key", JSON.stringify(data.Authorization));
    sessionStorage.setItem("user_info", JSON.stringify(data.data));
    sessionStorage.setItem("is_subscription", JSON.stringify(data.subscribe));
    sessionStorage.setItem(
      "subscription_data",
      JSON.stringify(data.subscription_data)
    );
    isModal ? setIsOpen(false) : navigate(-1);
    // toast.success(data.message);
    // window.location.reload(true);
    setTimeout(function () {
      window.location.reload(true);
    }, 50);
  };

  const signupAPI = (values) => {
    const formData = new FormData();
    formData.append("email_id", values.email_id);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("primary_contact_number", values.primary_contact_number);
    formData.append("dob", values.dob);
    formData.append("password", values.password);
    formData.append("is_web_signin", values.is_web_signin);
    formData.append("user_role_id", values.user_role_id);
    UserService.userSignUp(formData)
      .then((res) => {
        console.log(res.data);
        window.location.reload();
        sessionStorage.setItem(
          "auth_key",
          JSON.stringify(res.data.Authorization)
        );
        sessionStorage.setItem("user_info", JSON.stringify(res.data.data));
        setIsOpen(false);
        // toast.success(res.data.message);
      })
      .catch((e) => console.log(e));
  };

  const testPattern = (value) => {
    if (emailPattern.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  const isUserValid = (data) => {
    UserService.validateUser(data)
      .then((res) => {
        if (forgotPass) {
          console.log(res.data);
          if (res.data.success) {
            // sendOTP(res.data.data[0].email_id);
          } else {
            // toast.error(res.data.message);
          }
        } else {
          if (res.data.success) {
            setPasswordField(true);
            setTransform("29%");
            setbtnTitle("Login");
            setSignUpForm(false);
            setIsLogin(true);
          } else {
            setSignUpForm(true);
            setbtnTitle("Sign Up");
            setPasswordField(true);
            setIsLogin(false);
          }
        }
      })
      .catch((e) => console.log(e));

    setValidate(true);
  };

  const handleBlur = (value) => {
    if (value) {
      if (testPattern(value)) {
        const data = {
          email_id: value,
        };
        isUserValid(data);
      } else {
        const data = {
          primary_contact_number: value,
        };
        isUserValid(data);
      }
    } else {
      setPasswordField(false);
    }
  };

  //   const sendOTP = (email_id) => {
  //     if (email_id) {
  //       ForgotPasswordService.sendOTPEmail(email_id).then((res) => {
  //         if (res.data.success) {
  //           console.log("hdksjhfjkdshgkjsdhgkjhdskjghkj");
  //           setOTPValue("");
  //           setIsOTP(true);
  //         } else {
  //           //   toast.error(res.data.message);
  //         }
  //       });
  //     }
  //   };

  const forgotPassword = () => {
    setForgotPass(true);
    setbtnTitle("Send OTP");
    setPasswordField(false);
    setSignUpForm(false);
  };

  const setOTPValue = (e) => {
    console.log(e);
  };

  const { handleChange, handleSubmit } = formik;

  return (
    <div>
      <div
        className={
          btnTitle === "lets'go" && isModal
            ? "limiter-50"
            : btnTitle === "Login" && isModal
            ? "limiter-29"
            : btnTitle === "Sign Up" && isModal
            ? "limiter-0"
            : "limiter"
        }
      >
        <div
          className={
            isModal ? "modal-container-login100" : "container-login100"
          }
        >
          <div className="wrap-login100">
            <form
              className="login100-form validate-form"
              onSubmit={formik?.handleSubmit}
            >
              <div className="mb-3">
                <div className="wrap-input100 validate-input">
                  <input
                    className="input100 has-val"
                    placeholder="Email/Mobile No."
                    type="text"
                    name="email_id"
                    onChange={handleChange}
                  />
                  <span className="focus-input100"></span>
                </div>
              </div>

              {showSignUpForm ? (
                <div>
                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="First Name"
                          type="text"
                          name="first_name"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>

                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="Last Name"
                          type="text"
                          name="last_name"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="d-flex">
                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="Phone No."
                          type="text"
                          name="primary_contact_number"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>

                      <div className="wrap-input100 validate-input">
                        <input
                          className="input100 has-val"
                          placeholder="Date"
                          type="date"
                          name="dob"
                          onChange={handleChange}
                        />
                        <span className="focus-input100"></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {showPasswordField ? (
                <div className="mb-3">
                  <div
                    className="wrap-input100 validate-input"
                    data-validate="Enter password"
                  >
                    <span className="btn-show-pass">
                      <i className="zmdi zmdi-eye"></i>
                    </span>
                    <input
                      className="input100 has-val"
                      placeholder="Password"
                      type="password"
                      name="password"
                      onChange={handleChange}
                    />
                    <span className="focus-input100"></span>
                  </div>
                </div>
              ) : null}

              <div className="container-login100-form-btn">
                <div className="wrap-login100-form-btn">
                  <div className="login100-form-bgbtn"></div>
                  <button className="login100-form-btn" type="submit">
                    {btnTitle}
                  </button>
                </div>
              </div>
              <br />
              <div className="forgot-pass" onClick={() => setForgotPassword()}>
                <span>Forgot Password?</span>
              </div>

              {/* <div className="d-flex">
                                <div className="grey-line"></div>
                                <span className="font-10">Connect with</span>
                                <div className="grey-line"></div>
                            </div>

                            <div className="social-media">
                                <img src={facebookImg} />
                                <img src={googleImg} />
                            </div> */}
            </form>
          </div>
        </div>
      </div>

      <div id="dropDownSelect1"></div>
    </div>
  );
};

export default Login;
