import http from "../environment";

const getAll = () => {
  return http.get(`/content/get`);
};

const getByChapter = (id) => {
  return http.get(`/content/get?chapter_id=${id}`);
};

// const getByChapter = (id) => {
//   return http.get(`/content/get?chapter_id=${id}`);
// };

const ContentService = {
  getByChapter,
  getAll  
};

export default ContentService;
