import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MasterService from "../api config/master";
import TextBookService from "../api config/textBook";
import pic from "../assets/images/stdimg.png";
import "../Css/std.css";

const Std = (props) => {
  const [stdList, setStdList] = useState();
  const [boardId, setBoardId] = useState();
  const [textbookData, setTextbookData] = useState();

  const location = useLocation();

  // const handleBoardClick = (id) => {
  //   console.log(`Board clicked with id ${id}`);
  // };

  useEffect(() => {
    console.log(location?.state.id);
    setBoardId(location?.state.id);
    // board_id = location?.state.id;

    MasterService.stdGetAll().then(
      (res) => {
        console.log(res.data);
        setStdList(res.data.data);
        // console.log(boardId);
        // handleClickPdf(std_id, boardId);
      },
      [setStdList]
    );

    const id = props;
  }, [setBoardId]);

  const handleClickPdf = (std_id, boardId) => {
    console.log("calling");
    TextBookService.getByStdAndBoard(std_id, boardId).then(
      (res) => {
        if (res.data?.data[0]?.text_book) {
          // console.log(textbookData);
          let pdfUrl = `${res.data.data[0].text_book}`;
          // setTextbookData(pdfUrl);
          // console.log(textbookData);

          window.open(pdfUrl, "_blank");
          // return pdfUrl;
        } else {
          console.log("No text book founded");
        }
      },
      [setTextbookData]
    );
    // open pdf file in new tab
    // window.open(textbookData, "_blank");
  };

  return (
    <div className="container">
      <div className="row">
        {/* <iframe
          src="https://drive.google.com/file/d/18G5kXRBCU1A0ndy1b3iiOJKlQ5UVt-Ef/preview"
          // onClick={() => handleClickPdf(item.id, boardId)}
          width="640"
          height="480"
          allow="autoplay"
        ></iframe> */}
        {stdList?.map((item) => {
          return (
            <div key={item.id} className="col-12 col-sm-6 col-md-4  std-card">
              <div className="card">
                {/* <a onClick={() => handleClickPdf(item.id, boardId)} /> */}
                <a onClick={() => handleClickPdf(item.id, boardId)}>
                  {/* <iframe
                    src={textbookData}
                    // onClick={() => handleClickPdf(item.id, boardId)}
                    width="640"
                    height="480"
                    allow="autoplay"
                  > */}
                  {/* </iframe> */}

                  <ul className="list-group list-group-flush">
                    {/* <li className="list-group-item"> */}
                    <li>
                      <img src={pic} className="std-coverimage" />
                    </li>
                  </ul>
                  {/* </iframe> */}
                </a>
                <div className="card-footer">Std {item.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Std;
