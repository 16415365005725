import http from "../environment";

const getUser = (id) => {
  return http.get(`user/get?id=${id}`);
};

const userSignUp = (data) => {
  return http.post(`user/save`, data);
};

const validateUser = (data) => {
  return http.post(`user/login/validate`, data);
};

const userLogin = (data) => {
  return http.post(`user/login`, data);
};

const UserService = {
  getUser,
  userSignUp,
  validateUser,
  userLogin,
};

export default UserService;
