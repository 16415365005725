import http from "../environment";

const getAll = () => {
  return http.get(`/chapter/get`);
};
const getByLanguageAndStd = (language_id, std_id) => {
  return http.get(`/chapter/get?language_id=${language_id}&std_id=${std_id}`);
};

const ChapterService = {
  getAll,
  getByLanguageAndStd,
};

export default ChapterService;
