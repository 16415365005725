import http from "../environment";

const getAll = () => {
  return http.get(`/language/get`);
};


const LanguageService = {
  getAll,
};

export default LanguageService;
