import React, { useEffect, useState } from "react";
import "../Css/content.css"
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ContentService from "../api config/content";

const Content = () => {
  const [content, setContent] = useState();
//   const is_login = JSON.parse(sessionStorage.getItem('is_login'));
//   const user_info = JSON.parse(sessionStorage.getItem('user_info'));


    useEffect(() => {

        // getData();

        ContentService.getAll().then(
            (res) => {
              console.log(res.data);
              setContent(res.data.data);
            },
            [setContent]
          );
        
    },[])

    // const getData = () => {
    //     if (auth_token) {
    //         if (user_info.is_trial && (is_login || !is_login)) {
    //             ContentService.getByChapter()
    //                 .then(res => {
    //                     setContent(res.data.data);
    //                     getByChapter(res.data.data[0].id);
    //                 })
    //                 .catch(e => console.log(e));
    //         } else if (is_login && !user_info.is_trial) {
    //             ContentService.getAll(subscription_data[0].subscription_common_id, auth_token)
    //                 .then(res => {
    //                     setContent(res.data.data);
    //                     getByChapter(res.data.data[0].id);
    //                 })
    //                 .catch(e => console.log(e));
    //         } else {
    //             setNoData(true);
    //         }
    //     } else {
    //         ContentService.getAll()
    //             .then(res => {
    //                 setContent(res.data.data);
    //                 getByChapter(res.data.data[0].id);
    //             })
    //             .catch(e => console.log(e));
    //     }
    //     ContentService.getByChapter()
    //         .then(res => {
    //             console.log(res.data.data);
    //             setContent(res.data.data);
    //             getByChapter(res.data.data[0].id);
    //         })
    //         .catch(e => console.log(e));
    // }

    return(
        <section className="content">
            <div className="container">
                <div className="row">
                    <a href="chapter" className="back">
                      <AiOutlineArrowLeft/>  back
                    </a>
                </div>
                {content?.map((item) => {
          return (
            <><div className="exercise">
            <div className="row down" key={item.id}>
                  <h3 className="question">{item.question_number}. {item.question}</h3>
              </div><div className="row down">
                      <div className="col option">
                          <h5 className="opt">A. {item.option_1}</h5>
                          <h5 className="opt">B. {item.option_2}</h5>
                          <h5 className="opt">C. {item.option_3}</h5>
                          <h5 className="opt">D. {item.option_4}</h5>
                      </div>

                  </div>
                  <div className="row down">
                      <div className="col-9">
                          <h4 className="answer">Answer: {item.answer}</h4>
                      </div>
                      <div className="col-3">
                          <button type="button" className="explanation">
                              Explanation
                          </button>
                      </div>
                  </div>
                  </div>
                  </>
          )})}
            </div>
        </section>
        
    )
}

export default Content